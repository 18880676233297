import React, { useState, useEffect, Fragment } from 'react';
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";

import {Helmet} from "react-helmet";
import { useSelector, useDispatch } from 'react-redux'

import useMedia from "./js/js/useMedia"
import MainPage from "./js/MainPage"
import ShowDprt from "./js/DPRT/ShowDprt2022"
import ShowAltDprt from "./js/ShowAltDprt"
import Show_ITEM from "./js/ShowItem/Show_ITEM"
import GetInfoBlock from "./js/GetInfoBlock"
import Show_Contacts from "./js/Show_Contacts"
import About from "./js/About"
import News from "./js/News"
import GetBrands from "./js/BRANDS/GetBrands"
import Show_BRAND from "./js/BRANDS/Show_BRAND"
import Show_NEWS from "./js/Show_NEWS"
import AltCatalogue from "./js/Mobile/AltCatalogue"
import Loader from "./js/Loader"
import Shapka from "./js/Shapka"
import ViewBasket from "./js/basket/ViewBasket"

import SendOrder from "./js/basket/SendOrder"
import CallOrder from "./js/CallOrder/CallOrder"
import Partners from "./js/Partners"
import NotFound from "./js/NotFound"
import FloatBanner from "./js/BANNER/FloatBanner"

import LoadSearchResult  from "./js/SEARCH/LoadSearchResult"
import Base64 from "./js/Base64"

import './js/css/styles.css'
//import './bootstrap.min.css'
import './js/css/menu.css'
import './js/css/content.css'
import './js/css/effects.css'
import './js/css/nMenu.css'
import './js/css/eqSort.css'
import './js/css/infoblock.css'
import './js/css/gallery.css'
import './js/css/logo.css'
import './js/css/dialog.css'
import './js/css/footer.css'
//import { compose } from 'redux';


var rpfx = '';
var rttl = '';
var rman = '';
var title = 'Дельта Инжиниринг— КИП, электрооборудование, контрольно-измерительные приборы и средства автоматизации';
var renders = 0;

export default withRouter(App);

function App(props){

const dispatch = useDispatch()
const setup = useSelector(state => state.setup);

const [NavIDIs, setNavIDIs] = useState({loading:true, error:false});
const useQuery = () => new URLSearchParams(useLocation().search);
//let small = useMedia("(max-width:420px)");
let large = useMedia("(min-width:650px)");

let query = useQuery();
let mode = query.get('mode');

useEffect(() => { 
  //ReactGA.initialize('UA-6337752-1');
  if(mode === null) { 
    //ReactGA.initialize('UA-6337752-2', {debug: false});
   } else {
    dispatch({type:'SMODE', payload:false});
    }
  if(NavIDIs.loading){LoadNavIDs(); }
  }, [] );
//СЧЕТЧИКИ ================================================================================
  useEffect(() => { 
    SendMetrics('hit', props.location.pathname);
    }, [props.location.pathname] );

if(NavIDIs.error){
  return <p>AppJS: {NavIDIs.error.message}</p>
} 

if(NavIDIs.loading){ return <Shapka/> } 

return (
<Fragment>
  <Helmet>
  <title>{title}</title>
  </Helmet>
  <Shapka/>
  {
    large? 
    <div>
      <TopTelephones/>
    </div> : null
  }
  
  <section>
              <Switch>
                <Route exact path="/">
                  {
                  large? <MainPage/> : <AltCatalogue/>
                  }
                  <hr/>
                  <GetBrands/>
                  <Footer tel={true}/>
                  
                </Route>
                <Route path="/about">
                  <About/>
                  <Footer tel={true}/>
                </Route>
                <Route path="/search"><LoadSearchResult/><Footer tel={true}/></Route>
                <Route path="/news/:id"><Show_NEWS/><Footer tel={true}/></Route>
                <Route path="/news"><News/><Footer tel={true}/></Route>
                <Route path="/shop" component={ViewBasket}/>
                <Route path="/order" component={SendOrder}/>
                <Route path="/partners"><Partners mode="page"/><GetBrands/><Footer tel={false}/></Route>
                <Route path="/info/:id"><Show_NEWS/><Footer tel={true}/></Route>
                <Route path="/info"><NotFound/><Footer tel={true}/></Route>
                <Route path="/tag/:tag/:base/*/:id"><AltCatalogue/><Footer tel={false}/></Route>
                <Route path="/tag/:tag/:base/:id"><ShowAltDprt/><Footer tel={false}/></Route>
                <Route path="/tag/:tag"><AltCatalogue/><hr/><GetBrands/><Footer tel={false}/></Route>
                <Route path="/contacts"><Show_Contacts/><Footer tel={true}/></Route>
                <Redirect from='/:base/*/:pid/*~:id.html' to='/:base/*/:pid/*-:id.html' />
                <Redirect from='/:base/:pid/*~:id.html' to='/:base/:pid/*-:id.html' />
                <Route path="/:base/*/:pid/*-:id.html">
                  <Show_ITEM/>
                  <Footer tel={true}/>
                </Route>
                <Route path="/:base/:pid/*-:id.html">
                  <Show_ITEM/>
                  <Footer tel={true}/>
                </Route>
                <Route path="/brand/:id"><Show_BRAND/><Footer tel={true}/></Route>
                <Route path="/brand"><GetBrands/><Footer tel={true}/></Route>
                <Route path="/:base/*/:pid/:id"><ShowDprt level="3"/><GetBrands/><Footer tel={true}/></Route>
                <Route path="/:base/:pid/:id"><ShowDprt level="2"/><GetBrands/><Footer tel={true}/></Route>
                <Route path="/:base/:id"><ShowDprt level="1"/><GetBrands/><Footer tel={true}/></Route>
                <Route path="/:base">
                  <ShowDprt level="0"/>
                  <GetBrands/>
                  <Footer tel={true}/>
                </Route>
              </Switch>
    </section>
  <FloatBanner/>
  <div id="lbcont"/>
  </Fragment>
  );

  function SendMetrics(type, path){
    if(setup.smode){
     window.ym('13155013',type, path);
     window.gtag('set', 'page_path', path);
     window.gtag('event', 'page_view');
    }
    return null
  }


function LoadNavIDs(){
    const form = new FormData()
          form.append('baseQ', setup.bases);
          form.append('lang', setup.lang);
    const MyInit = { method: 'POST', body: form };
    fetch(setup.domen+setup.folder+'init', MyInit )
      .then(response => {
        if (response.ok) {
          return response.json();
          } else {
            throw new Error(response.text()+'Something went wrong ...');
          }
        })
      .then(data => { 
        rman = data.itm.man;
        rpfx = data.itm.pfx;
        rttl = data.itm.ttl;

        if(rttl !=='none') { title = ''; }
        if(rman !== 'none') { title = rman+' ' }
        if(rttl !=='none') { title = title+rttl; }
        if(rpfx !== 'none') { title= title + ' — '+rpfx }

//        console.log('TITLE:', title);

        let man = data.man.i;
        let mans = [];
        let manid = [];
        let kurs = [];
        let tags = [];
        let ttl = [];
        let bases_id2num = [];
        let bases_id2itm = [];

        dispatch({type:'SET_META', payload:{ttl:Base64.decode(data.site.ttl), dsc:Base64.decode(data.site.dsc), kwrd:Base64.decode(data.site.kwrd)}});
        //console.log('NETA:', data.site);
        man.map((i, key) => mans[i.ttl] = i);
        man.map((i, key) => manid[i.id] = i);
        //console.log('BARNDS:', mans['ОВЕН'].img);
        dispatch({type:'SET_BRANDS', payload:{ttl:mans, ids:manid}});
        data.navid.map((i, key)=>{ bases_id2num[i.id] = key });
        data.navid.map((i, key)=>{ bases_id2itm[i.id] = i });
        dispatch({type:'SET_NAVBASES', payload:{nums:bases_id2num, itms:bases_id2itm}});
        dispatch({type:'LOAD_NAV_IDS', payload:data.navid});
        kurs['RUR'] = data.kurs.rur;
        kurs['EUR'] = data.kurs.eur;
        kurs['USD'] = data.kurs.usd;
        dispatch({type:'SET_KURS', payload:kurs});
        data.alt.mnu.map((i, id) => {
          tags[i.id] = i.tag;
          ttl[i.id] = i.ttl;
        })
        dispatch({type:'SET_TAGS', payload:{list: data.alt, tags:tags, ttl:ttl}});
        //dispatch({type:'SET_CUR_ID', payload:data.navid[0].id});
        setNavIDIs({loading:false, error:false });  
       })
      .catch(error => { setNavIDIs({ error:error, loading:true }); })
}
}

function Footer(p) {
  return (
    <div className="footer">
      <noindex>
        { p.tel?<CallOrder/> :null }
      </noindex>
      <GetInfoBlock blk="footer"/>
    </div>
  );
}

function TopTelephones(p) {
  return (
    <div className="toptels">
    <div>
      <noindex>
Поставки КИПиА и электрооборудование со склада в Челябинске и под заказ.  
Официальный представитель ведущих российских и зарубежных производителей средств измерения.     
Комплектация заказа на 100%. 
Компетентная техническая поддержка при подборе оборудования.
      </noindex>
    </div>
      <GetInfoBlock blk="toptels"/>
    </div>
  );
}

function Show404(){
  return(
    <Fragment>
    <p className="tac b f10">ВЫ ПОПАЛИ НА НЕСУЩЕСТВУЮЩУЮ СТРАНИЦУ</p>
    <div className="notfound">404</div>
    <p className="tac b">НИЧЕГО НЕ НАЙДЕНО</p>
    </Fragment>
  )
}


function Dashboard(){
  return (
    <div className="pages">
      <h2>УСЛУГИ</h2>
    </div>
  );
}


