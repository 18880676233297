import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from 'react-redux'


import Base64 from "../Base64"
import GetMenuMobile from "../Mobile/GetMenuMobile"
import GetStepNavigation from "../StepNavigation_MOB"
import AddComment from "../AddComment"
import GetPrice from "../GetPrice"
import ShowComments from "../Comments/ShowComments"
import { Link } from "react-router-dom";
import ScrollUp from "../ScrollUp"

//import * as $ from 'jquery';
import LightBox from '../js/lb'
//import '../css/jquery.lightbox-0.5.css'
import '../css/content.css'
import './showItem.css'

export default function Ext_Dprt_Mobile(props) {

  const setup = useSelector(state => state.setup);
  const brands_by_ttl = useSelector(state => state.brands.ttl);
  const ShowPictures = useMemo(() => <ShowImages content={props.data} mode='_sm' domen={setup._domen}/>, [props.data]);
  const ShowDSC = useMemo(() => <ShowContent content={props.data.dsc_txt} />, [props.data.dsc_txt]);
  const ShoweItems = useMemo(() => <Show_eItems itm={props.data.edprt.itm} domen={setup._domen}/>, [props.data]);

  useEffect(() => {
    LightBox('a.lbpic');
  }, [props.data.dsc_txt] );

  


  const itm = props.data;
  var t = Base64.decode(itm.t);
  var p = Base64.decode(itm.pfx);

  var ttl = itm.man + ' ' + t + ' — ' + p;
  var mttl = ttl + ' — купить в Дельта Инжиниринг. г. Челябинск.';

  let mDsc = Base64.decode(itm.meta_dsc);
  var metDsc = mDsc !== 'nodata' ? mDsc : itm.man + ' ' + Base64.decode(itm.t) + ' — ' + Base64.decode(itm.sm_dsc);

  let genKwrds = `${itm.man}, ${t}, ${p}`;
  let kwrds = Base64.decode(itm.kwrd);
  var keywords = kwrds ==='nodata'? genKwrds : `${genKwrds}, ${kwrds}`;


  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{mttl}</title>
        <meta name="description" content={metDsc} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={setup._domen + itm.url} />
      </Helmet>

      <div className="item pages">
        <GetMenuMobile man={itm.man} />
        <h1 className="itmttl"><span className="f10">{p}</span><br />{itm.man} {t}</h1>
        <GetStepNavigation />
        <table><tbody>
          <tr>
            <td className="content">
              <AddComment />
              <div className="gallery">
                {ShowPictures}
              </div>
              {
                itm.zak === "y" ? <a href="#price" className="btn qst w">ЦЕНЫ</a> : null
              }
              {ShowDSC}
              {ShoweItems}
              {
                itm.zak !== "n" ? <GetPrice
                  id={itm.id}
                  ttl={{ p: Base64.decode(itm.pfx), t: Base64.decode(itm.t), v: itm.man }}
                  vmode={itm.vmode}
                  vendor={brands_by_ttl[itm.man].id}
                  modif={itm.modif}
                  prc={itm.prc}
                  valuta={itm.log}
                />
                  : null
              }
              <ShowComments />
            </td>
          </tr>
        </tbody></table>
        <ScrollUp/>
      </div>
    </Fragment>
  )

function Show_eItems(props) {
  const itm = props.itm;
  //console.log('SHOW', itm);
    return (
      <ul className="edprt">
        { itm[0]? 
         itm.map((i, key) =>
          <li>
            <a href={i.url}>{Base64.decode(i.pfx)} {Base64.decode(i.t)}</a>
              <ShowImagePlusContent content={i.sm_dsc} data={i} domen={props.domen}/>
          </li>
          )
          :
          <li>
            <a href={itm.url}>{Base64.decode(itm.pfx)} {Base64.decode(itm.t)}</a>
              <ShowImagePlusContent content={itm.sm_dsc} data={itm} domen={props.domen}/>
          </li>
        }
      </ul>
)
}

function ShowImagePlusContent(props){
  const dsc_txt = props.content;
  //$(() => {$('a#lbpic').lightBox(); })
            return(
          <div id="content" dangerouslySetInnerHTML={{ __html: ShowImageToString({content:props.data, mode:'_sm', domen:props.domen})+sanitize(Base64.decode(dsc_txt)) }} />
          )
}

function ShowImageToString(props){
  let RESULT ='';
  const itm = props.content;
  const imgs = props.content.imgs;
  const imges = Base64.decode(props.content.imges);
  const imgttl = props.content.ttlpfx+'-';
  const domen = props.domen;
      
  if(imgs === ''){ 
        const ext = imges.slice(-4);
        const impath = imges.slice(0, -4);
        RESULT = `<a href="${itm.url}" class="fl"><img src="${domen}${impath}${props.mode}${ext}" alt="${ttl}" title="${ttl}"/></a>`
        } else {
    return ( imgs.img[0]?
      imgs.img.slice(0,1).map((i, key) => (i.p === 'y' ?
        RESULT = `<a href="${itm.url}" class="fl"><img src="${domen}/${imgttl}${i.src.slice(0, i.src.indexOf('.jpg'))}${props.mode}.jpg" alt="${ttl}" title="${ttl}"/></a>`
        : null
        ))
      :
      RESULT = `<a href="${itm.url}" class="fl"><img src="${domen}/${imgttl}${imgs.img.src.slice(0, imgs.img.src.indexOf('.jpg'))}${props.mode}.jpg" alt="${ttl}" title="${ttl}"/></a>`
      )
    }
    return RESULT;
}

// Показываем одну каринку для EDPRT
function ShowImage(props){
  const itm = props.content;
  const imgs = props.content.imgs;
  const imges = Base64.decode(props.content.imges);
  const imgttl = props.content.ttlpfx+'-';
      
  if(imgs === '' ){ 
    const ext = imges.slice(-4);
        const impath = imges.slice(0, -4);
    return <Link to={itm.url}><img src={props.domen + impath + props.mode + ext} alt={ttl} title={ttl} /></Link>
        } else {
    return ( imgs.img[0] ?
                    imgs.img.slice(0,1).map((i, key) => (i.p === 'y' ?
                  <Link key={key} to={itm.url}>
          <img src={props.domen +'/'+ imgttl + i.src.slice(0, i.src.indexOf('.jpg')) + props.mode + '.jpg'} alt={ttl} title={ttl} />
        </Link>: null))
      :
        <Link to={itm.url}>
          <img src={props.domen +'/'+ imgttl + imgs.img.src.slice(0, imgs.img.src.indexOf('.jpg')) + props.mode + '.jpg'} alt={ttl} title={ttl} />
        </Link>
        )

    }
}
        
function ShowImages(props){
  const imgs = props.content.imgs;
        const imges = Base64.decode(props.content.imges);
        const imgttl = props.content.ttlpfx+'-';
      
  if(imgs === '' ){ 
   const ext = imges.slice(-4);
        const impath = imges.slice(0, -4);
   return <a href={props.domen + imges} className="lbpic"><img src={props.domen + impath + props.mode + ext} alt={ttl} title={ttl} /></a>
        } else {
    return ( imgs.img[0] ?
                    imgs.img.map((i, key) => (i.p === 'y' ?
                  <a key={key} href={props.domen + imgttl + i.src} className="lbpic">
          <img src={props.domen +'/'+ imgttl + i.src.slice(0, i.src.indexOf('.jpg')) + props.mode + '.jpg'} alt={ttl} title={ttl} />
        </a>: null))
      :
                  <a href={props.domen + imgttl + imgs.img.src} className="lbpic">
          <img src={props.domen +'/'+ imgttl + imgs.img.src.slice(0, imgs.img.src.indexOf('.jpg')) + props.mode + '.jpg'} alt={ttl} title={ttl} />
        </a>
        )

    }
}

function ShowContent(props){
const dsc_txt = props.content;
//$(() => {$('a#lbpic').lightBox(); })
        return(
        <div id="content" dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(dsc_txt)) }} />
        )
      }

      function sanitize(html) {
        var doc = document.createElement('div');
              doc.innerHTML = html;
              return doc.innerHTML;
}      
      }; 
     
      
