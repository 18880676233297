import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";
import Base64 from "./Base64"
import Loader from "./Loader"
import AddtoBasket from './basket/AddToBasket'
import number_format from "./js/number_format"
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";

import "./css/price.css"

var modList = [];
var prices = [];
var MinPrice = 0;

export default function GetPrice(props){
    const dispatch = useDispatch()
    const setup = useSelector(state => state.setup);
    //const bases = useSelector(state => state.navbases.nums);
    //const brands = useSelector(state => state.brands.ids);
    const kurs = useSelector(state => state.kurs);
    const basket = useSelector(state => state.basket);
    const bqwn = useSelector(state => state.bqwn);
    
    const [AllIs, setAllIs] = useState({loading:true, error:null});
    const [list, setList] = useState([]);
    const [fr, setFr] = useState(0);


    let { id } = useParams();
    //let { path, url } = useRouteMatch();

    useEffect(() => {
        if(!props.modif.qwn){
            let mList = Base64.decode(props.modif);
             LoadPrice(mList, props.vendor, kurs[props.valuta]); 
            } else {
                setAllIs({loading:false, error:null});
            }
      }, [props.modif] );
      
const ModificationList = useMemo(()=>ShowModification(list),[list, fr]);
const OldModificationList = useMemo(()=> ShowModification(props.modif.m?props.modif.m:[]), [props.modif.m]);

function mangle(param){
    var regExp1 = '/5/gi';
    var regExp2 = '/2/gi';
    var replacement1 = "#";
    var replacement2 = "%";
    param = param.replace(regExp1, replacement1);
    param = param.replace(regExp2, replacement2);
    return param;
}

/*
 $keyword = 'шифровочка';
$q = '123';
$hash = md5($keyword.$q);
if(md5($keyword.$_GET['q']) == $_GET['hash']) echo 'OK';
*/


if(AllIs.error){
    return <p>MAIN APP: {AllIs.error.message}</p>
} 

if(AllIs.loading){ return <Loader/> } 

//console.log('MODLIST', modList);

return (
<Fragment>
  <table id="price" className="price" cellPadding="0" cellSpacing="0">
  <caption className="red"><h2>ЦЕНЫ НА ПРОДУКЦИЮ</h2></caption>
    <tbody>
      {
        modList[0]?
        <tr>
          <td colSpan="3">
            <input placeholder='Фильтр по модели..' type="text" onChange={handleSearch} autoCorrect="off" autoComplete="off"/>
          </td>
        </tr>
        : null
      }
        <tr><th width="75%" align="left">Наименование</th><th width="15%" align="left">Цена, руб.</th><th width="10%"></th></tr>
            {props.modif.qwn? OldModificationList
              : 
              props.modif !=='nodata'? ModificationList
              :
              <tr className={ basket.has(props.id)? basket.get(props.id).qwn > 0? 'inbasket' :null:null }>
                {tdshki({id:props.id, ttl:(props.ttl.p+' '+props.ttl.v+' '+props.ttl.t), mdl:props.ttl.t, prc:props.prc*parseFloat(kurs[props.valuta])})}
              </tr>
            }
    </tbody>
  </table>    
</Fragment>
)

function forceReload(qwn){
  setFr(qwn);
}

function tdshki(itm){
  if(itm.prc != 0 ){
        return <Fragment>
          <td>{itm.ttl}
            <p>mdl: <i>{itm.mdl}</i></p>
            {basket.has(itm.id)? basket.get(itm.id).qwn > 0?
            <p className="center"> (<Link to="/shop">В корзине - <b>{basket.get(itm.id).qwn}</b> шт.</Link>)</p> : null : null
            }
          </td>
          <td><span>{number_format(itm.prc, 2, '.', ',')}</span></td>
          <td><AddtoBasket itm={itm} ttl={{t:props.ttl.t, p:props.ttl.p, v: props.ttl.v}} fr={forceReload}/></td>
        </Fragment>
    } else {
      return (null)
    }
}

//new Intl.NumberFormat('ru-RU').format(itm.prc)

function ShowModification(mod){
  //console.log('SHOW MODIF');
    return( 
        mod[0]? 
        mod.map((itm, i) => 
            <tr key={i} className={ basket.has(itm.id)? basket.get(itm.id).qwn > 0? 'inbasket' :null:null  } >
              {tdshki(itm)}
            </tr>)
    : 
    mod.ttl?
    <Fragment>
        {//<tr><td>{mod.ttl}</td><td>{number_format(mod.prc, 2, '.', ' ')}</td></tr><td><AddtoBasket itm={mod} ttl={props.ttl}/></td>
        }
        {//tdshki({id:props.id, ttl:mod.ttl, mdl:props.mdl, prc:mod.prc})
        }
        <tr>
          {tdshki(mod)}
        </tr>
    </Fragment>
    :
    null
)
}

function handleSearch(event){
	let mnu = modList.slice();
  //var t = new (Date()).getTime();
  var searchQuery = event.target.value.toLowerCase();
    
	var FilteredList = mnu.filter((el) => {
	  var searchValue = el.mdl.toLowerCase();
	  return searchValue.indexOf(searchQuery) !== -1;});

    setList(FilteredList);
}

function LoadPrice(modif, vendor, kurs){
    setAllIs({loading:true, error:null});
    dispatch({type:'SET_MIN_PRC', payload:0});
    let summ = 0;
    prices = [];
    MinPrice = 0;

    const form = new FormData()
          form.append('id', id);
          form.append('str', modif);
          form.append('vendor', vendor);
          form.append('kurs', kurs);
          form.append('lang', setup.lang);

    const MyInit = { 
                method: 'POST',
                body: form
                };
  fetch(setup.domen+setup.folder+'get-modif-list', MyInit)
    .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.text()+'Something went wrong ...');
        }
    })
    .then(data => {
        if(data.m.itm){ 
                modList = data.m.itm;
if(data.m.itm[0]){

                modList.map((i, key)=>{ 
                       prices[key] = i.prc;
                       summ = summ+eval(prices[key]);
                  });
                
                let maxPrice =  Math.max(...prices);

                let treshold = maxPrice/100*10;
                let ptr = 0;
                let NewPrc = [];
                
                for(let i = 0; i < prices.length; i++ ){

                  if (prices[i] > treshold) {
                    NewPrc[ptr] = prices[i]; 
                    ptr++;
                  }

                }

                MinPrice = Math.min(...NewPrc);

                //props.MinPrice(MinPrice);
} else {
  MinPrice = modList.prc;
}
                dispatch({type:'SET_MIN_PRC', payload:MinPrice});
                setList(modList);
                setAllIs({loading:false, error:null});
        }
        })
    .catch(error => {
        setAllIs({loading:true, error:error});
     });
  }
 
}
