import React, { useState, useEffect, useMemo, Fragment } from 'react';
import useMedia from "../js/useMedia"
import { useSelector, useDispatch } from 'react-redux'

//import DeskTop from "./DeskTop"
import DeskTop from "./DeskTop_Tabbed"
import Ext_Dprt_Tabbed from "./Ext_Dprt_Tabbed"
import Mobile from "./Mobile"
import Ext_Dprt_Mobile from "./ShowEdprtMobile"
import NotFound from "../NotFound"

import { useParams, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import Loader from '../Loader';

var small = false;

export default function Show_ITEM(){

  const setup = useSelector(state => state.setup);
  const bases = useSelector(state => state.navbases.nums);
  
const [content, setContent] = useState({loading:true, error:null, data:{dsc_txt:'', edprt:{itm:[]}}});
const [notFound, setNotFound] = useState(false);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let { base, id } = useParams();
let { url } = useRouteMatch();
let history = useHistory();

small = useMedia("(max-width:650px)");

let query = useQuery();
let oid = query.get("oid")? query.get("oid"): 'nan';

useEffect(() => {
  window.scroll(0, 0);
  loadContent(id, bases[base], oid); 
}, [id, url] );

if(notFound){return <NotFound/>}
if(content.error){ return <p>MAIN APP: {content.error.message}</p> } 
if(content.loading){ return (
  <div style={{'height':'100px'}}>
    <Loader/> 
  </div>
)
} 

return(
  <Fragment>

{
    content.data.vmode!=='edprt'?
        small ? <Mobile data={content.data}/> : <DeskTop data={content.data}/>
        :
        small ? <Ext_Dprt_Mobile data={content.data}/> : <Ext_Dprt_Tabbed data={content.data}/>
}
    </Fragment>

)





function GoBack(){
  history.goBack();
}

function reDirect(nUrl){
  setContent({loading:true, error:null, data:null});
  history.push({ pathname: nUrl, state: { status: 301 } }) 
  //console.log('URRRRL');
  //console.log('irl', itm.url);
  //console.log('url', url);
  return <Loader/>  
}

function loadContent(cid, base, oid){
  const form = new FormData()
        //form.append('BaseNumber', setup.base);
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
        form.append('oid', oid);
  const MyInit = { 
              method: 'POST',
              body: form
              };
        //fetch(setup.domen+setup.folder+'site-get-itm.php', MyInit)
        fetch(setup.domen+'api/item', MyInit)
        .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(response.text()+'Something went wrong ...');
            }
        })

        .then(data => {   
                          //dispatch({type:'LOAD_CONTENT', payload:data});
                          if( data.url === '' ){ setNotFound(true); }
                          if (url !== data.url) {  reDirect(data.url); } 
                          else {
                                setContent({loading:false, error:null, data:data});
                               }

                       })
        .catch(error => {
             setContent({loading:true, error:error, data:null});
         });
}

}



